.slideshowContainer{
    max-height: 100%;
    max-width: 400px;
    position: relative;
    /* margin: auto; */
}

.slides{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    /* background-size: cover; */
    /* background-position: center; */
    /* transition: opacity 0.5s ease; */
    display: none;
    max-width: 400px;
}

.slides.active{
    display: block;
    background-color: #717171;
    max-width:400px;
}

.slideshowImage{
    max-width: 400px;
}

.prev, .next{
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px 6px;
    color: black;
    font-weight: bold;
    font-size: 18px;
    /* transition: 0.6s ease; */
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: rgba(0,0,0,0.2);
}

.next{
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prev {
    left:0;
}

.prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
    color:white;
}

.numberText{
    /* color: #f2f2f2; */
    color:white;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    bottom: 0;
    margin:auto;
    border-radius: 0 3px 0 0;
    background-color: rgba(0,0,0,0.2);
}