@import url('https://fonts.googleapis.com/css2?family=LXGW+WenKai+Mono+TC:wght@300;400;700&family=PT+Mono&family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');


.app {
  text-align: center;
}

.header {
  text-align: center;
  color: #623894;
  font-family: "LXGW WenKai Mono TC", monospace;
  font-weight: 700;
  font-size: 20px;
}

.banner {
  max-width: 100%;
  height: auto;
}

.pageBody {
  width: 90%;
  margin: auto;
}

.bodyText {
  width: 45%;
  margin: auto;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.specialHeading{
  color: #623894;
  font-family: 'Courgette', cursive;
  font-style: normal;
  font-weight: 400;
  font-size:30px;
  text-align:center;
}



/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
